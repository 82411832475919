<template>
  <div class="width-100 height-100 max_box">
    <component :is="currentView" :cardData = 'cardData' ref="coms" @playEnd="playNext()"></component>
  </div>
</template>

<script>
import $ from "jquery";
import { GetParams2,sleep } from "@/utils/common";
import ApiServe from "@/api/index";
import OneComponent from "./one.vue"
import ThreeComponent from "./three.vue"
import FourComponent from "./four.vue"
export default {
  name: "ZongXinIndex",
  components:{
      OneComponent,
      ThreeComponent,
      FourComponent
  },
  data() {
    return {
        aduioData:[],   //旁白的数据
        cardData:{},
        currentIndex:1
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
    // this.getAudio();
  },
  computed: {
    allReady() {
      const {  aduioData } = this;
      return {
        aduioData,
      };
    },
    // 录屏服务区分本地和线上
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    currentView(){
      if(this.currentIndex == 1){
        return OneComponent
      }
      if(this.currentIndex == 2){
        return ThreeComponent
      }
      if(this.currentIndex == 3){
        return FourComponent
      }
      return OneComponent
    }
  },
  async mounted(){
    // await sleep(5000)
    this.load();
  },
  watch: {
    //  数据准备好了开始调录屏服务
    allReady(val) {
        console.log(val)
      if (val.aduioData.length > 0) {
        if (this.ip && this.port) {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
        //   ApiServe.recordstartPc(this.newIpPort, params)
        //     .then(({ code }) => {})
        //     .catch((err) => {
        //       ApiServe.recordreleasePc(this.newIpPort, {
        //         ip: this.ip,
        //         port: this.port,
        //       });
        //     });
        //   this.load();
        } else {
          alert("没获取到ip,port,本地测试,非录屏");
          this.load();
        }
      }
    },
  },
  methods: {
    getData() {
      let params = {
        model_id: 59,
      };
      ApiServe.getDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.cardData = data
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    // 获取旁白
    getAudio() {
      let params = {
        model_id: this.model_id,
      };
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {

          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    // 录屏结束 处理旁白数据--传给后端
    endRecord() {
      let arr = [
        3,
        4 + this.aduioData[0].scene_duration,
        5 + this.aduioData[0].scene_duration + this.aduioData[1].scene_duration,
        6 +
          this.aduioData[0].scene_duration +
          this.aduioData[1].scene_duration +
          this.aduioData[2].scene_duration,
      ];
      let audiosOrderList = this.aduioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: arr[index],
          audioLen: item.audio_length,
        };
      });
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.videoTotalTime,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    async load() {
      this.$refs.coms.playCardStart()
      // 页面播放完之后调取录屏结束服务
    //   if (this.ip) {
    //     setTimeout(() => {
    //       this.endRecord();
    //     }, 40000);
    //   }
    },
    playNext(){
      this.currentIndex ++;
      this.$nextTick(()=>{
        this.$refs.coms.playCardStart()
      })
      
    }

  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>
<template>
  <!-- 页面27-37 陈忱 -->
  <div class="width-100 height-100">
    <div class="card1 card" id="card1" v-show="currentIndex == 1">
      <div class="content_box">
        <div class="title animate__animated none">
          中信证券财富优选一年持有（原中信证券基金精选）
        </div>
        <div id="chart1"></div>
      </div>
    </div>
    <div class="card2 card" id="card2" v-show="currentIndex == 2">
      <div class="card2_title">背后管理人及团队</div>
    </div>
    <div class="card3 card" id="card3" v-show="currentIndex == 3">
      <div class="card3_title">中信证券财富优选一年持有FOF投资经理</div>
      <div class="manager">
        <img src="../../assets/img/zh_manager.png" alt="" />
      </div>
      <ul>
        <li class="manager_name">陈晓非</li>
        <li class="li1">加拿大多伦多大学工业工程学士、硕士。</li>
        <li class="li2">中信证券资产管理副总裁</li>
        <li class="li3">6年国内券商资管投研经历</li>
        <li class="li4">
          擅长大类资产配置及组合优化方法，负责FOF指数化策略开发及基金评价体系基础研究。
        </li>
        <!-- <li class="li5">化策略开发及基金评价体系基础研究。</li> -->
      </ul>
    </div>
    <div class="card4 card" id="card4" v-show="currentIndex == 4">
      <div class="text_content">
        <div class="text1">中信证券自1998年开始经营资产管理业务</div>
        <div class="text2 none">
          <span class="mark_red none" id="company_year">23</span
          >年丰富的投资管理经验
        </div>
        <div class="text3 none">截止2020年底，中信证券资管业务受托管</div>
        <div class="text4 none">总规模<span class="mark_red">1.36万亿</span>元</div>
      </div>
    </div>
    <div class="card5 card" id="card5" v-show="currentIndex == 5">
      <div class="text_content">
        <div class="text1">于<span class="mark_red">2004</span>年开始</div>
        <div class="text2 none">
          深耕<span class="mark_red">FOF</span>投研<span
            class="mark_red none"
            id="year2"
            >17</span
          >年
        </div>
      </div>
    </div>
    <div class="card6 card" id="card6" v-show="currentIndex == 6">
      <div class="text_content">
        <div class="text1">
          持续跟踪<span class="mark_black">全市场</span>明星基金公司及基金经理
        </div>
        <div class="text2">
          <span class="mark_black">现场</span>非现场调研超<span class="mark_red"
            >500</span
          >次/每年
        </div>
      </div>
    </div>
    <div class="card7 card" id="card7" v-show="currentIndex == 7">
      <div class="text_content">
        <div class="text1">
         常年跟踪基金超过<span class="mark_red">9000</span>只
        </div>
        <div class="text2">
          实现公募基金公司研究全覆盖<span class="mark_black">全覆盖</span>
        </div>
      </div>
    </div>
    <div class="card8 card" id="card8" v-show="currentIndex == 8">
      <div class="text_content">
        <div class="text1">你可以永远相信中信在资管领域的投资经验</div>
      </div>
    </div>
    <div class="card9 card" id="card9" v-show="currentIndex == 9">
      <div class="content_box">
        <div class="cup_box">
          <img src="../../assets/img/zx_cup.png" alt="" />
        </div>
        <div class="text1 none">东方财富风云榜评选</div>
        <div class="text2 none">中国财富管理机构君鼎奖</div>
        <div class="text3 none">金牛理财产品评选</div>
        <div class="text4 none">英华奖</div>
      </div>
    </div>
    <div class="card10 card card_table" id="card10" v-show="currentIndex == 10">
      <div class="table_box">
        <div class="th">
          <div class="left">项目</div>
          <div class="right">内容</div>
        </div>
        <div class="body">
          <div class="row">
            <div class="left">产品名称</div>
            <div class="right">
              中信证券财富优选一年持有期混合型基金中基金（FOF）集合资产管理计划
            </div>
          </div>
          <div class="row">
            <div class="left">产品类型</div>
            <div class="right">混合型基金中基金集合资产管理计划</div>
          </div>
          <div class="row">
            <div class="left">产品代码</div>
            <div class="right">A类份额：900012 C类份额：900112</div>
          </div>
          <div class="row">
            <div class="left">运作方式</div>
            <div class="right">契约型开放式</div>
          </div>
          <div class="row">
            <div class="left">管理人</div>
            <div class="right">中信证券股份有限公司</div>
          </div>
          <div class="row">
            <div class="left">托管人</div>
            <div class="right">中信证券股份有限公司</div>
          </div>
          <div class="row">
            <div class="left">申购起点</div>
            <div class="right">
              首次申购本集合计划份额的单笔最低限额为人民币1元（含申购费），追加申购单笔最低限额为1元
            </div>
          </div>
          <div class="row">
            <div class="left">业绩比较标准</div>
            <div class="right">
              中证800指数收益率x80%+中债综合财富（总值）指数收益率x20%
            </div>
          </div>
          <div class="row heigt_cust">
            <div class="left">风险收益特征</div>
            <div class="right">
              本集合计划为混合型基金中基金集合计划，在通常情况下其风险收益水平高于债券型基金、货币市场基金、债券型基金中基金和货币型基金中基金，低于股票型基金和股票型基金中基金。
            </div>
          </div>
          <div class="row heigt_cust1">
            <div class="left">投资范围</div>
            <div class="right">
              集合计划的投资范围为具有良好流动性的金融工具，包括经中国证监会依法核准或注册的公开募集证券投资基金的基金份额(包括QDII基金、商品基金（含商品期货基金和黄金ETF）、香港互认基金)、国内依法发行的股票（包括主板、创业板以及其他中国证监会允许投资的股票)、债券（包括国债、地方政府债、金融债、企业债、公司债、次级债、可转换债券（含分离交易可转债）、央行票据、中期票据、短期融资券（含超短期融资券）、可交换债券）、资产支持证券、债券回购、银行存款（包括定期存款、协议存款、通知存款等）、同业存单以及法律法规或中国证监会允许公开募集证券投资基金投资的其他金融工具(但须符合中国证监会相关规定)。
            </div>
          </div>
        </div>
      </div>
      <div class="bg_box"></div>
    </div>
    <div class="card11 card card_table" id="card11" v-show="currentIndex == 11">
      <div class="table_box">
        <div class="th">
          <div class="left">项目</div>
          <div class="right">内容</div>
        </div>
        <div class="body">
          <div class="row cust_height">
            <div class="left">投资组合比例</div>
            <div class="right">
              集合计划的投资组合比例为：本集合计划投资于经中国证监会依法核准或注册的公开募集证券投资基金的基金份额投资比例不低于集合计划资产的80%，其中股票、股票型基金、混合型基金和商品基金（含商品期货基金和黄金ETF）投资占集合计划资产的比例合计为60%-95%（此处混合型基金还应至少满足以下标准之一：（1）基金合同约定的股票资产占基金资产的比例不低于60%；（2）最近四个季度报告披露的持有股票资产占基金资产比例均不低于60%。）；货币市场基金投资占集合计划资产的比例不得超过15%；现金或者到期日在一年以内的政府债券不低于集合计划资产净值的5%，其中，现金不包括结算备付金、存出保证金、应收申购款等；其他金融工具的投资比例依照法律法规或监管机构的规定执行。
            </div>
          </div>
          <div class="row custom-row">
            <div class="left">
              申购费
              <div class="text-grey">（仅A类份额收取）</div>
            </div>
            <div class="right">
              <div class="custom-col">
                <div class="thead">
                  <div class="lft">申购确认金额（M）</div>
                  <div class="right">申购费率</div>
                </div>
                <div class="row1">
                  <div class="lft">M＜100万</div>
                  <div class="right">1.0%</div>
                </div>
                <div class="row1">
                  <div class="lft">100万≤M＜300万</div>
                  <div class="right">0.8%</div>
                </div>
                <div class="row1">
                  <div class="lft">300万≤M＜500万</div>
                  <div class="right">0.6%</div>
                </div>
                <div class="row1">
                  <div class="lft">M≥500万</div>
                  <div class="right">每笔1000元</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row cust_row">
            <div class="left">
              销售服务费
              <div class="text-grey">（仅C类份额收取)</div>
            </div>
            <div class="right">
              本集合计划A类计划份额不收取销售服务费，C类计划份额的销售服务费年费率为0.5%
            </div>
          </div>
          <div class="row bg">
            <div class="left">赎回费</div>
            <div class="right">
              本集合计划A类计划份额和C类计划份额在一年最短持有期限后赎回不收取赎回费用
            </div>
          </div>
          <div class="row">
            <div class="left">管理费</div>
            <div class="right">1%/年</div>
          </div>
          <div class="row bg">
            <div class="left">托管费</div>
            <div class="right">0.15%/年</div>
          </div>
          <div class="row">
            <div class="left">业绩报酬</div>
            <div class="right">无</div>
          </div>
        </div>
      </div>
      <div class="bg_box2"></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
export default {
  name: "FourComponent",
  props: {
    cardData: Object,
  },
  data: function () {
    return {
      currentIndex: 1,
      chart1_date: [],
      chart1_line1: [],
      chart1_line2: [],
      chart1_line3: [],
    };
  },
  components: {},
  watch: {
    cardData: {
      handler(val) {
        console.log(val);
        if (val && val.chart_data_2 && val.chart_data_2[0].data) {
          this.chart1_date = val.chart_data_2[0].data.DATE || [];
          this.chart1_line1 = val.chart_data_2[0].data["业绩基准"] || [];
          this.chart1_line2 =
            val.chart_data_2[0].data["中信证券财富优选一年持有"] || [];
          this.chart1_line3 = val.chart_data_2[0].data["中证800指数"] || [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 4, this.options);
      num.start();
    },

    //card6
    async page6In() {
      this.page6Text1 = anyElement({
        targets: document.querySelectorAll("#card6 .text1"),
        duration: 1000,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page6Text2 = anyElement({
        targets: document.querySelectorAll("#card6 .text2"),
        duration: 1000,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page6Text1.goIn.restart();
      await sleep(800);
      this.page6Text2.goIn.restart();
      await sleep(800);
      $(".text2 .mark_red").addClass("animate__animated animate__zoomIn");

      await sleep(2000);
      this.page6Out();
    },
    async page6Out() {
      this.page6Text1.goOut.restart();
      await sleep(800);
      this.page6Text2.goOut.restart();
      await sleep(2000);
      $("#card6").addClass("none");
    },

    //card7
    async page7In() {
      this.page7Text1 = anyElement({
        targets: document.querySelectorAll("#card7 .text1"),
        duration: 1000,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page7Text2 = anyElement({
        targets: document.querySelectorAll("#card7 .text2"),
        duration: 1000,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page7Text1.goIn.restart();
      await sleep(800);
      this.page7Text2.goIn.restart();
      $(".text1 .mark_red").addClass("animate__animated animate__zoomInDown")
      await sleep(2000);
      this.page7Out();
    },
    async page7Out() {
      this.page7Text1.goOut.restart();
      await sleep(800);
      this.page7Text2.goOut.restart();

      await sleep(2000);
      $("#card7").addClass("none");
    },

    //card8

    async page8In() {
      this.page8Text1 = anyElement({
        targets: document.querySelectorAll("#card8 .text1"),
        duration: 1000,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "typeIn",
        outEffect: "fadeOut",
      });
      this.page8Text1.goIn.restart();
      await sleep(2000);
      this.page8Out();
    },
    async page8Out() {
      this.page8Text1.goOut.restart();
      await sleep(2000);
      $("#card8").addClass("none");
    },

    async playCardStart() {
      //页面播放
      await sleep(1000);
       // card1
      this.currentIndex = 1;
      $(".title").removeClass("none");
      $(".title").addClass("animate__animated animate__backInLeft");
      await sleep(800);
      this.loadChart1();
      await sleep(3000);
      $("#card1").addClass("animate__animated animate__backOutRight");
      await sleep(1000);

      // //card2
      this.currentIndex = 2;
      $(".card2_title").addClass("animate__animated animate__bounceInDown");
      await sleep(900);
      $("#card2").addClass("animate__animated animate__fadeOut");
      await sleep(2000);

      card3;
      this.currentIndex = 3;
      $(".card3_title").addClass("animate__animated animate__bounceIn");
      await sleep(1000);
      $(".manager_name").addClass(
        "animate__animated animate__lightSpeedInRight"
      );
      await sleep(800);
      $(".li1").addClass("animate__animated animate__bounceInRight");
      await sleep(800);
      $(".li2").addClass("animate__animated animate__bounceInRight");
      await sleep(800);
      $(".li3").addClass("animate__animated animate__bounceInRight");
      await sleep(800);
      $(".li4").addClass("animate__animated animate__bounceInRight");
      await sleep(1000);
      $("#card3").addClass("animate__animated animate__fadeOut");
      await sleep(1000);

      //card4
      this.currentIndex = 4;
      $(" .card4 .text1").addClass("animate__animated animate__bounceInLeft");
      await sleep(1000);
$(" .card4 .text2").removeClass("none")
      $(" .card4 .text2").addClass("animate__animated animate__bounceInLeft");
      await sleep(1000);
      $(".card4 .text2 .mark_red").removeClass("none");
      this.startNumber("company_year", 23, 0);
      await sleep(1000);
      $(" .card4 .text3").removeClass("none")
      $(" .card4 .text3").addClass("animate__animated animate__bounceInLeft");
      await sleep(1000);
      $(" .card4 .text4").removeClass("none")
      $(" .card4 .text4").addClass("animate__animated animate__bounceInLeft");
      await sleep(1000);
      $(".card4 .text4 .mark_red").addClass(
        "animate__animated animate__rotateInDownLeft"
      );
      await sleep(1000);
      $("#card4").addClass("animate__animated  animate__fadeOut");
      await sleep(1000);

      //card5
      this.currentIndex = 5;
      $(".text1").addClass("animate__animated animate__bounceInDown");
      await sleep(1000);
      $(".text2").removeClass("none");
      $(".text2").addClass("animate__animated animate__bounceInUp");
      $(".text2 .mark_red").removeClass("none");
      this.startNumber("year2", 17, 0);
      await sleep(2000);
      $("#card5").addClass("animate__animated animate__fadeOut");
      await sleep(2000);

      //card6
      this.currentIndex = 6;
      this.page6In();
      await sleep(2000);

      //card7
      this.currentIndex = 7;
      this.page7In();
      await sleep(2000);

      //card8
      this.currentIndex = 8;
      this.page8In();
      await sleep(2000);

      //card9
      this.currentIndex = 9;
      $(".text1").removeClass("none");
      $(".text1").addClass("animate__animated animate__bounceInDown ");
      await sleep(800);
      $(".text2").removeClass("none");
      $(".text2").addClass("animate__animated animate__bounceInRight ");
      await sleep(800);
      $(".text3").removeClass("none");
      $(".text3").addClass("animate__animated animate__bounceInRight ");
      await sleep(800);
      $(".text4").removeClass("none");
      $(".text4").addClass("animate__animated animate__bounceInUp ");
      await sleep(1000);
      $("#card9").addClass("none");
      await sleep(1000);

      //card10
      this.currentIndex = 10;
      $(".bg_box").animate({ height: "0px" });
      await sleep(3000);
      $("#card10").addClass("animate__animated animate__zoomOutRight");
     
      await sleep(1000);

      //card11
      this.currentIndex = 11;
      $(".bg_box2").animate({ height: "0px", width: "0px" });
    },

    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption(
        {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.chart1_date,
            axisTick: {
              show: true,
              inside: true,
              length: 14,
              lineStyle: {
                color: "#979797",
                width: 4,
                height: 14,
              },
            },
            axisLabel: {
              align: "left",
              textStyle: {
                fontSize: 22,
                color: "#333333",
              },
            },
            axisLine: {
              show: true,
              onZero: false,
              lineStyle: {
                color: "#979797",
                width: 4,
              },
            },
          },
          legend: {
            show: true,
            //   type: "plain",
            icon: "line",
            data: ["中信财富优选一年持有", "业绩基准", "中证800指数"],
            bottom: -5,
            itemGap: 30,
            itemWidth: 79,
            itemHeight: 14,
            textStyle: {
              fontSize: 22,
              color: "#333333",
            },
          },
          animationDuration: 4000,
          grid: {
            top: 290,
            left: 190,
            right: 50,
            bottom: 90,
          },
          yAxis: [
            {
              type: "value",
              show: true,
              name: "",
              boundaryGap: [0, "100%"],
              position: "left",
              min: this.min_chart1,
              max: this.max_chart1,
              axisTick: {
                show: true,
                inside: true,
                length: 14,
                lineStyle: {
                  color: "#979797",
                  width: 4,
                  height: 14,
                },
              },
              axisLabel: {
                textStyle: {
                  fontSize: 22,
                  color: "#333333",
                },
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#979797",
                  width: 4,
                },
              },
            },
          ],
          series: [
            {
              name: "业绩基准",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#006DC3",
                width: 6,
              },
              color: "#006DC3",
              data: this.chart1_line1.slice(0, 2000),
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
            {
              name: "中信财富优选一年持有",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#D40100",
                width: 6,
              },
              color: "#D40100",
              data: this.chart1_line2.slice(0, 2000),
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
            {
              name: "中证800指数",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#A6A6A6",
                width: 6,
              },
              color: "#A6A6A6",
              data: this.chart1_line3.slice(0, 2000),
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
          ],
          markLine: {
            symbol: ["none", "none"],
            label: { show: false },
            data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
          },
        },
        true
      );
    },
  },
  async mounted() {
    this.playCardStart();
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
@import "./four.less";
</style>


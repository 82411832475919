<template>
    <div class="width-100 height-100 content" id="boxesGroup1">
      <div class="audio_box none">
        <audio ref="audio1" src="audio/zhongxin/1.mp3"></audio>
        <audio ref="audio2" src="audio/zhongxin/2.mp3"></audio>
        <audio ref="audio3" src="audio/zhongxin/3.mp3"></audio>
        <audio ref="audio4" src="audio/zhongxin/4.mp3"></audio>
        <audio ref="audio5" src="audio/zhongxin/5.mp3"></audio>
        <audio ref="audio6" src="audio/zhongxin/6.mp3"></audio>
        <audio ref="audio7" src="audio/zhongxin/7.mp3"></audio>
        <audio ref="audio8" src="audio/zhongxin/8.mp3"></audio>
        <audio ref="audio9" src="audio/zhongxin/9.mp3"></audio>
        <audio ref="audio10" src="audio/zhongxin/10.mp3"></audio>
        <audio ref="audio11" src="audio/zhongxin/11.mp3"></audio>
      </div>
      <div class="card1 card animate__animated" v-show="currentIndex == 1">
        <div class="translate_text" id="text1">
          本视频内容涉及理财产品，风险等级为<span class="color-A70B12 letter">R3（中风险）</span>，适合<span class="color-A70B12 letter">C3及以上</span>
          投资者，基金投资有风险，入市需谨慎。
        </div>
      </div>
      <div class="card2 card animate__animated " v-show="currentIndex == 2">
        <div class="fofimg_box">
          <img src="../../assets/img/zx_fof.png" alt="">
          <div class="squre squrel">
            <img src="../../assets/img/zxsqurel.png" alt="">
          </div>
          <div class="squre squrer">
            <img src="../../assets/img/zxsqurer.png" alt="">
          </div>
        </div>
        <div class="logo_box">
          <img src="../../assets/img/zxlogo.png" alt="">
        </div>
      </div>
      <div class="card3 card animate__animated animate__fadeIn" v-show="currentIndex == 3">
        <div class="translate_text card3_text" id="card3_text">
          还在为选择基金类型而烦恼吗？
        </div>
      </div>
      <div class="card4 card animate__animated animate__fadeIn" v-show="currentIndex == 4">
        <div class="header">
          <span class="h_l">公募基金数量</span><span class="h_r">＞13000只</span>
        </div>
        <div class="chart" id="chart1"></div>
        <div class="sign none">
          <img src="../../assets/img/zxmore.png" alt="">
        </div>
      </div>
      <div class="card5 card animate__animated animate__fadeIn" v-show="currentIndex == 5">
        <div class="bg_box">
          <img class="bg_img" src="../../assets/img/zhongxin/zxbg1.png" alt="">
          <span>保险基金</span>
          <span>二级债基</span>
          <span>指数型</span>
          <span>股票基金</span>
          <span>偏股混合型</span>
          <span>香港互认基金</span>
          <span>Reits</span>
          <span>QDII</span>
          <span>货币基金</span>
          <span>普通股票型</span>
          <span>偏债混合型</span>
          <span>商品型</span>
          <span>公司型基金</span>
          <span>信托基金</span>
          <span>货币基金</span>
          <span>纯债基金</span>
          <span>平衡型基金</span>
        </div>
        <div class="sign none">
          <img src="../../assets/img/zhongxin/zxsign2.png" alt="">
        </div>
      </div>
      <div class="card6 card animate__animated animate__fadeIn" v-show="currentIndex == 6">
        <div class="translate_text" id="text2">
          还在为
        </div>
        <div class="translate_text none" id="text3">
          选择一支优质基金而烦恼吗
        </div>
      </div>
      <div class="card7 card animate__animated animate__fadeIn" v-show="currentIndex == 7">
        <div class="header">近三年偏股混合及股票型基金收益表现</div>
        <div class="table_box none">
          <div class="t_header">
            <span>近三年收益率前10</span><span>累计收益率</span>
            <span>近三年收益率后10</span><span>累计收益率</span>
          </div>
          <div class="t_body">
              <div class="t_body_1 t_body_div">
                <div v-for="(item,index) in card7TableData['近三年基金收益率前10']" :key="index">
                  {{item}}
                </div>
              </div>
              <div class="t_body_2 t_body_div">
                <div v-for="(item,index) in card7TableData['累计收益率1']" :key="index">
                  {{item}}%
                </div>
              </div>
              <div class="t_body_3 t_body_div">
                <div v-for="(item,index) in card7TableData['近三年基金收益率后10']" :key="index">
                  {{item}}
                </div>
              </div>
              <div class="t_body_4 t_body_div">
                <div v-for="(item,index) in card7TableData['累计收益率2']" :key="index">
                  {{item}}%
                </div>
              </div>
              <div class="scal_2 scal_2_l none">
                419.28%
              </div>
              <div class="scal_2 scal_2_r none">
                -8.9%
              </div>
          </div>
        </div>
        <div class="sign sign1 none">
          <img src="../../assets/img/zhongxin/zxsign3.png" alt="">
        </div>
        <div class="sign sign2 none">
          <img src="../../assets/img/zhongxin/zxsign4.png" alt="">
        </div>
      </div>
      <div class="card8 card animate__animated animate__fadeIn" v-show="currentIndex == 8">
        <span class="main_span">择时为王</span>
        <span>情绪性</span>
        <span>专业性</span>
        <span>择时困难</span>
        <span>紧跟市场</span>
        <span>动态跟踪</span>
        <span>预测走势</span>
        <span>舍不得卖</span>
        <span>耗费精力</span>
      </div>
      <div class="card9 card animate__animated animate__fadeIn" v-show="currentIndex == 9">
        <div class="translate_text" id="text4">
          <span class="font_large">还</span>在为
        </div>
        <div class="translate_text none" id="text5">
          选择合适的<span class="font_large">时间</span>点
        </div>
        <div class="translate_text none" id="text6">
         而<span class="font_large">烦恼</span>吗
        </div>
      </div>
      <div class="card10 card animate__animated animate__fadeIn" v-show="currentIndex == 10">
        <div class="header">公募基金募集规模与万得全A指数走势</div>
        <div class="chart none" id="chart2"></div>
        <div class="meng_ban none">高点买入基金可能造成本金或机会损失</div>
      </div>
      <div class="card11 card animate__animated animate__fadeIn" v-show="currentIndex == 11">
        <div class="translate_text" id="text7">
          我该如何选择
        </div>
        <div class="translate_text none" id="text8">
          中信证券
        </div>
        <div class="translate_text none" id="text9">
          FOF
        </div>
      </div>
      <div class="card12 card animate__animated animate__fadeIn" v-show="currentIndex == 12">
        <div class="bg_box">
          <div class="translate_text text">
            中信证券财富优选一年持有FOF
          </div>
        </div>
      </div>
      <div class="card13 card animate__animated animate__fadeIn" v-show="currentIndex == 13">
        <div class="bg_box">
          <div class="translate_text text1 text">
            让更专业的人帮你选优质基金、管理基金
          </div>
          <div class="translate_text text2 text none">
            FOF基金三大优势
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from '@/utils/common';
export default {
  data: function () {
    return {
      currentIndex:1
    };
  },
  props:['cardData'],
  computed:{
    card4ChartData(){
      if(this.cardData.chart_data_1){
        return this.cardData.chart_data_1[0].data
      }
      return {}
    },
    card7TableData(){
      if(this.cardData.chart_data_3){
        return this.cardData.chart_data_3[0].data
      }
      return {}
    },
    card10ChartData(){
      if(this.cardData.chart_data_4){
        return this.cardData.chart_data_4[0].data
      }
      return {}
    },
  },
  methods: {
    playIn(inEffect, outEffect,dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();   
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart(){
        this.currentIndex = 1
        this.playIn('fadeIn', 'fadeOut','#text1')
        await sleep(3000)
        $('.card1').addClass('animate__zoomOutRight')
        await sleep(1000)
        this.currentIndex = 2
        this.$refs.audio1.play()
        $('.fofimg_box').addClass('animate__animated animate__backInDown');
        $('.logo_box').addClass('animate__animated animate__backInUp')
        await sleep(4000)
        $('.card2').addClass('animate__fadeOutRight')
        await sleep(1000)
        this.currentIndex = 3
        this.playIn('perspectiveRightIn3', 'fadeOut','#card3_text') 
        await sleep(2000)
        $('.card3').addClass('animate__zoomOutRight')
        await sleep(1000)
        this.currentIndex = 4
        $('.card4 .header').addClass('animate__animated animate__bounceInLeft')
        this.$refs.audio2.play()
        await sleep(1000)
        this.loadChart1()
        await sleep(3000)
        $('.card4 .sign').removeClass('none')
        $('.card4 .sign').addClass('animate__animated animate__jackInTheBox');
        await sleep(2000)
        $('.card4').addClass('animate__fadeOutRight')
        await sleep(1000)
        this.currentIndex = 5
        $('.card5 span').eq(0).animate({opacity:1},100)
        $('.card5 span').eq(0).animate({left:'158px',top:'56px'},1000)
        $('.card5 span').eq(1).animate({opacity:1},100)
        $('.card5 span').eq(1).animate({left:'425spx',top:'2px'},1000)
        $('.card5 span').eq(2).animate({opacity:1},100)
        $('.card5 span').eq(2).animate({left:'-48px',top:'232px'},1000)
        $('.card5 span').eq(3).animate({opacity:1},100)
        $('.card5 span').eq(3).animate({left:'446px',top:'133px'},1000)
        await sleep(1000)
        $('.card5 .bg_img').addClass('img_rate')
        $('.card5 span').eq(4).animate({opacity:1},100)
        $('.card5 span').eq(4).animate({left:'126px',top:'234px'},800)
        $('.card5 span').eq(5).animate({opacity:1},100)
        $('.card5 span').eq(5).animate({left:'375px',top:'255px'},800)
        $('.card5 span').eq(6).animate({opacity:1},100)
        $('.card5 span').eq(6).animate({left:'728px',top:'285px'},800)
        $('.card5 span').eq(7).animate({opacity:1},100)
        $('.card5 span').eq(7).animate({left:'-80px',top:'362px'},800)
        await sleep(1000)
        $('.card5 span').eq(8).animate({opacity:1},100)
        $('.card5 span').eq(8).animate({left:'46px',top:'371px'},600)
        $('.card5 span').eq(9).animate({opacity:1},100)
        $('.card5 span').eq(9).animate({left:'558px',top:'391px'},600)
        $('.card5 span').eq(10).animate({opacity:1},100)
        $('.card5 span').eq(10).animate({left:'-80',top:'467px'},600)
        $('.card5 span').eq(11).animate({opacity:1},100)
        $('.card5 span').eq(11).animate({left:'254px',top:'510px'},600)
        await sleep(1000)
        $('.card5 span').eq(12).animate({opacity:1},100)
        $('.card5 span').eq(12).animate({left:'479px',top:'515px'},400)
        $('.card5 span').eq(13).animate({opacity:1},100)
        $('.card5 span').eq(13).animate({left:'30px',top:'613px'},400)
        $('.card5 span').eq(14).animate({opacity:1},100)
        $('.card5 span').eq(14).animate({left:'247',top:'651px'},400)
        $('.card5 span').eq(15).animate({opacity:1},100)
        $('.card5 span').eq(15).animate({left:'703px',top:'606px'},200)
        $('.card5 span').eq(16).animate({opacity:1},100)
        $('.card5 span').eq(16).animate({left:'446',top:'700px'},200)
        await sleep(1000)
        $('.card5 .sign').removeClass('none')
        $('.card5 .sign').addClass('animate__animated animate__jackInTheBox');
        await sleep(2000)
        $('.card5').addClass('animate__fadeOutDown')
        await sleep(1000)
        this.currentIndex = 6
        this.playIn('perspectiveRightIn3', 'fadeOut','#text2')
        await sleep(1000)
        $('.card6 #text2').addClass('none')
        $('.card6 #text3').removeClass('none')
        this.playIn('perspectiveRightIn3', 'fadeOut','#text3')
        await sleep(2000)
        $('.card6').addClass('animate__zoomOutRight')
        await sleep(1000)
        this.currentIndex = 7
        $('.card7 .header').addClass('animate__animated animate__zoomInDown');
        await sleep(1500)
        $('.card7 .table_box').slideDown(1000);
        await sleep(1000)  
        this.$refs.audio3.play() 
        $('.card7 .scal_2').removeClass('none');
        $('.card7 .scal_2').addClass('animate__animated animate__zoomIn');
        await sleep(1000)
        $('.card7 .sign1').removeClass('none');
        $('.card7 .sign1').addClass('animate__animated animate__jackInTheBox');
        await sleep(1500)
        $('.card7 .sign1').addClass('none');
        $('.card7 .sign2').removeClass('none');
        $('.card7 .sign2').addClass('animate__animated animate__jackInTheBox'); 
        await sleep(1500)
        $('.card7').addClass('animate__animated animate__zoomOutRight')
        await sleep(1000)
        this.currentIndex = 8
        $('.card8 span').addClass('animate__animated animate__rotateIn')
        this.$refs.audio4.play()
        await sleep(1500)
        $('.card8 .main_span').removeClass('animate__rotateIn')
        $('.card8 .main_span').addClass('animate__shakeX')
        await sleep(7000) 
        $('.card8').addClass('animate__animated animate__fadeOutRight')
        await sleep(1000)
        this.currentIndex = 9
        $('.card9 #text4').addClass('animate__animated animate__zoomInDown')
        await sleep(1000)
        $('.card9 #text5').removeClass('none')
        $('.card9 #text5').addClass('animate__animated animate__zoomInDown')
        await sleep(1000)
        $('.card9 #text6').removeClass('none')
        $('.card9 #text6').addClass('animate__animated animate__zoomInDown')
        await sleep(1500)
        $('.card9').addClass('animate__animated animate__fadeOutRight')
        await sleep(1000) 
        this.currentIndex = 10
        $('.card10 .header').addClass('animate__animated animate__rotateIn')
        this.$refs.audio5.play()
        await sleep(1000)
        $('.card10 .chart').removeClass('none')
        this.loadChart2()
        await sleep(2500)
        $('.card10 .meng_ban').removeClass('none')
        $('.card10 .meng_ban').addClass('animate__animated animate__flipInX')
        await sleep(1500)
        $('.card10').addClass('animate__animated animate__fadeOutRight')
        await sleep(1000)
        this.currentIndex = 11
        $('.card11 #text7').addClass('animate__animated animate__backInUp')
        this.$refs.audio6.play()
        await sleep(3000)
        $('.card11 #text7').addClass('none')
        $('.card11 #text8').removeClass('none')
        $('.card11 #text8').addClass('animate__animated animate__backInUp')
        this.$refs.audio7.play()
        await sleep(1000)
        $('.card11 #text8').addClass('none')
        $('.card11 #text9').removeClass('none')
        $('.card11 #text9').addClass('animate__animated animate__backInUp') 
        await sleep(1500)
        $('.card11').addClass('animate__animated animate__fadeOutUp')
        await sleep(1000)
        this.currentIndex = 12
        $('.card12 .text').addClass('animate__animated animate__flipInX')
        this.$refs.audio8.play()
        await sleep(4000)
        $('.card12').addClass('animate__animated animate__fadeOutRight')
        await sleep(1000)
        this.currentIndex = 13
        $('.card13 .text1').addClass('animate__animated animate__lightSpeedInLeft')
        this.$refs.audio9.play()
        await sleep(5000)
        $('.card13 .text1').addClass('animate__animated animate__lightSpeedOutRight')
        await sleep(1000)
        $('.card13 .text2').removeClass('none')
        $('.card13 .text2').addClass('animate__animated animate__zoomIn')
        this.$refs.audio10.play()
        await sleep(4000)
        $('.card13').addClass('animate__animated animate__fadeOutRight')
        await sleep(1000)
        this.$emit('playEnd')
    },
    // 公募基金数量的图表
    loadChart1() {
      console.log(this.card4ChartData)
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "100px",
          top: "60px",
          bottom: "100px",
          right: "0",
        },
        legend: {
          data: ['存续基金数量','存续股票数量'],
          bottom:40,
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "category",
            data:this.card4ChartData['日期'],
            axisTick: { show: false },
            axisLine:{
              lineStyle:{
                color:'#9A9A9A'
              }
            },
            axisLabel:{
              color:'#9A9A9A'
            }
          },
        ],
         yAxis: [
          {
            type: "value",
            show:true
          },
        ],
        series: [
          {
            name:'存续基金数量',
            type: "bar",
            data: this.card4ChartData['存续基金数量'],
            itemStyle: {
              color:'#4C5664'
            },
          },
          {
            name:'存续股票数量',
            type: "bar",
            data: this.card4ChartData['存续股票数量'],
            itemStyle: {
              color:'#D40100'
            },        
          },
        ],
      };
      myChart.setOption(option,true);
    },
    // 公募基金募集规模的图表
    loadChart2() {
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "100px",
          top: "20px",
          bottom: "100px",
          right: "20px",
        },
        legend: {
          data: ['募集规模（亿元）','Wind全A（右轴）'],
          bottom:40,
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "category",
            data:this.card10ChartData['DATE'],
            axisTick: { show: false },
            axisLine:{
              lineStyle:{
                color:'#9A9A9A'
              }
            },
            axisLabel:{
              color:'#9A9A9A'
            }
          },
        ],
         yAxis: [
          {
            type: "value",
            show:true
          }
        ],
        series: [
          {
            name:'募集规模（亿元）',
            type: "bar",
            data: this.card10ChartData['募集规模'],
            itemStyle: {
              color:'#4C5664'
            },
          },
          {
            name:'Wind全A（右轴）',
            type: "line",
            data: this.card10ChartData['Wind全A'],
            itemStyle: {
              color:'#D40100'
            },        
          },
        ],
      };
      myChart.setOption(option,true);
    },
  },
  // async mounted() {
  //   this.playCardStart()
  //   // this.currentIndex = 9
      
  // },
};
</script>
<style scoped lang="less">
@import "./index.less";
.content{
  font-size: 60px;
}
.sign{
    position: absolute;
    right: 113px;
    top: 120px;
    width: 387px;
    height: 387px;
  }
.translate_text{
  position: absolute;
  left: 239px;
  right: 10%;
  top: 360px;
  text-align: center;
  line-height: 120px;
  font-size: 100px;
  /deep/ .color-A70B12{
    color: #A70B12;
  }
  /deep/ .letter{
    display: inline-block;
  } 
}
.card1{
  .translate_text{
    font-size: 68px;
  }
}
.card2{
  background: url('../../assets/img/zxbg2.png');
  background-size: cover;
  .fofimg_box{
    width: 1368px;
    height: 163px;
    position: absolute;
    top: 348px;
    left: 276px;
    text-align: center;
  }
  .logo_box{
    position: absolute;
    bottom:180px;
    left: 493px;
    width: 858px;
    height: 125px;
  }
  .squre{
    position: absolute;
  }
  .squrel{
    left: -73px;
    bottom: -79px;
  }
  .squrer{
    right: -73px;
    top: -79px;
  }
}
.card3{
  .card3_text{
    font-size: 100px;
    top: 470px;
    /deep/ .letter{
      display: inline-block;
    }
  }
}
.card4{
  .header{
    position: absolute;
    left: 141px;
    top: 144px;
    .h_r{
      font-size: 40px;
      color: #333;
    }
  }
  #chart1{
    width: 1163px;
    height: 707px;
    position: absolute;
    bottom: 60px;
    left: 356px;
  }
}
.card5{
  .bg_box{
    position: absolute;
    top: 139px;
    left: 537px;
    width: 753px;
    height: 802px;
    // img{
    //   width: 100%;
    //   height: 100%;
    // }
  }
  .bg_img{
    transition: All 18s ease-in-out;
    -webkit-transition: All 18s ease-in-out;
    -moz-transition: All 18s ease-in-out;
    -o-transition: All 18s ease-in-out;
  }
  .img_rate{
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
  span{
    position: absolute;
    width:300px;
    top: 40%;
    left: 40%;
    opacity: 0;
  }
  span:nth-of-type(1),span:nth-of-type(14),span:nth-of-type(17){
    color: #32C5FF;
    font-size: 24px;
  }
  span:nth-of-type(2),span:nth-of-type(3),span:nth-of-type(7),span:nth-of-type(8),span:nth-of-type(15),span:nth-of-type(16){
    color: #0091FF;
    font-size: 32px;
  }
  span:nth-of-type(4),span:nth-of-type(9),span:nth-of-type(13){
    color: #0091FF;
    font-size: 28px;
  }
  span:nth-of-type(5),span:nth-of-type(10),span:nth-of-type(12){
    color: #005494;
    font-size: 32px;
  }
  span:nth-of-type(6),span:nth-of-type(11){
    color: #005494;
    font-size: 38px;
  }
}
.card7{
  .header{
    position: absolute;
    top: 155px;
    width: 100%;
    text-align: center;
  }
  .table_box{
    font-size: 28px;
    position: absolute;
    top: 259px;
    left: 311px;
    right: 311px;
    height: 700px;
    >div{
      display: flex;
      >span{
        width: 25%;
      }
    }
    .t_header{
      font-size: 32px;
      padding: 30px 0px;
      background-image: linear-gradient(to right, #C3161C , #DD5C30);
      color: #fff;
      text-align: center;
    }
    .t_body{
      display: flex;
      border:3px solid #C3161C;
      .t_body_div{
        width: 25%;
        text-align: center;
        >div{
          height: 50px;
          line-height: 50px;
        }
        >div:nth-of-type(2n+1){
          background: rgba(216, 216, 216, 0.4);
        }
        >div:last-of-type{
          background: rgba(195, 22, 28, 0.4);
          color: #C3161C;
          padding: 8px 0;
        }
      }
      .scal_2{
        position: absolute;
        width: 319px;
        height: 165px;
        line-height: 165px;
        text-align: center;
        border: 4px solid #E02020;
        border-radius: 50%;
        background: #F5F5F5;
        font-size: 50px;
      }
      .scal_2_l{
        left: 25%;
        top: -25px;
      }
      .scal_2_r{
        right: -87px;
        bottom: 80px;
      }
    }
  }
}
.card8{
  span{
    position: absolute;
  }
  span:nth-of-type(1){
    font-size: 88px;
    left: 773px;
    top: 500px;
  }
  span:nth-of-type(2){
    font-size: 48px;
    left: 1000px;
    top: 344px;
  }
  span:nth-of-type(3){
    font-size: 42px;
    left: 704px;
    top: 381px;
  }
  span:nth-of-type(4){
    font-size: 32px;
    left: 1175px;
    top: 460px;
  }
  span:nth-of-type(5){
    font-size: 32px;
    left: 562px;
    top: 472px;
  }
  span:nth-of-type(6){
    font-size: 60px;
    left: 430px;
    top: 576px;
  }
  span:nth-of-type(7){
    font-size: 32px;
    right: 519px;
    top: 626px;
  }
  span:nth-of-type(8){
    font-size: 38px;
    left: 690px;
    top: 712px;
  }
  span:nth-of-type(9){
    font-size: 38px;
    right: 704px;
    top: 694px;
  }
}
.card9{
  #text4{
    top: 364px;
    right: 1300px;
    font-size: 60px;
  }
  #text5{
    top: 440px;
    right: 774px;
    text-align: right;
    font-size: 60px;
  }
  #text6{
    top: 554px;
    right: 400px;
    text-align: right;
    font-size: 60px;
  }
  .font_large{
    font-size: 100px;
  }
}
.card10{
  .header{
    position: absolute;
    top: 155px;
    width: 100%;
    text-align: center;
  }
  #chart2{
    width: 1190px;
    height: 700px;
    position: absolute;
    top: 260px;
    left: 362px;
  }
  .meng_ban{
    background: rgba(1, 26, 46, 0.8);
    height: 260px;
    left: 0;
    right: 0;
    position: absolute;
    top: 370px;
    color: #fff;
    padding-left: 427px;
    padding-right: 323px;
    font-size: 100px;
    text-align: center;
    padding: 60px 400px;

  }
}
.card11{
  font-size: 100px;
}
.card12{
  .bg_box{
    position: absolute;
    top: 82px;
    bottom:66px;
    left: 77px;
    right: 85px;
    background: url('../../assets/img/zhongxin/zxbg2.png') no-repeat 100% 100%;
    background-size: cover;
    // animation-iteration-count: infinite;
  }
  .text{
    background: rgba(153, 5, 13, 1);
    color: #fff;
    padding: 10px 0;
  }
}
.card13{
  .text{
    left: 20%;
    right: 20%;
  }
}

</style>


<template>
  <div class="width-100 height-100">
    <!-- 第19页 -->
    <div key="19" class="cardSteps card card19" v-if="currentPage == 19">
      <div class="audio_box"> 
          <audio ref="audio11" src="../../../public/audio/zhongxin/11.mp3"></audio>
      </div>
      <div class="topLetter">
        <anime-txt
          ref="refAnimeTxt19"
          id="refAnimeTxt19"
          class="anime_txt"
          txt="FOF投资超额收益的来源"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="perspectiveRightIn3"
          outEffect="fadeOut"
        />
      </div>
      <div class="roudCircle">
        <img
          src="../../assets/img/zhongxin/page21_circleBg.png"
          width="100%"
          height="100%"
        />
      </div>
      <img
        class="arrow1"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <img
        class="arrow2"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <img
        class="arrow3"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <div class="line line1"></div>
      <div class="textBlock textBlock1 blockActive1">
        <div class="inner">
          核心策略一：大类资产配置 <br />
          · 战略配置<br />
          · 战术配置
        </div>
      </div>
    </div>
    <!-- 第20页 -->
    <div class="card card20" v-if="currentPage == 20">
       <div class="audio_box"> 
          <audio ref="audio12" src="../../../public/audio/zhongxin/12.mp3"></audio>
      </div>
      <div class="topLetter">
        <anime-txt
          ref="refAnimeTxt20"
          id="refAnimeTxt20"
          class="anime_txt"
          txt="市场变化导致基金业绩分化"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="typeIn"
          outEffect="fadeOut"
        />
      </div>
      <div class="barChartWrap">
        <div class="block block1">
          <div class="tittle tittle1">2020-Q2牛市</div>
          <div class="flexRow">
            <div class="chart" id="chart1"></div>
            <ul class="column column1">
              <li v-for="(item, index) in bardata[0]" :key="index">
                {{ item }}%
              </li>
            </ul>
          </div>
        </div>
        <div class="block block2">
          <div class="tittle tittle2">2020年下半年震荡</div>
          <div class="flexRow">
            <div class="chart" id="chart2"></div>
            <ul class="column column2">
              <li v-for="(item, index) in bardata[1]" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div class="block block3">
          <div class="tittle tittle3">2021年春节后抱团股大跌</div>
          <div class="flexRow">
            <div class="chart" id="chart3"></div>
            <ul class="column column3">
              <li v-for="(item, index) in bardata[2]" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="blackRow">
        <anime-txt
          ref="refAnimeTxt20b"
          id="refAnimeTxt20b"
          class="anime_txt"
          txt="资产配置带来超额收益"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="fadeIn"
          outEffect="fadeOut"
        />
      </div>
    </div>
    <!-- 第21页 -->
    <div key="21" class="cardSteps card card21" v-if="currentPage == 21">
       <div class="audio_box"> 
          <audio ref="audio13" src="../../../public/audio/zhongxin/13.mp3"></audio>
      </div>
      <div class="topLetter">
        <anime-txt
          ref="refAnimeTxt21"
          id="refAnimeTxt21"
          class="anime_txt"
          txt="FOF投资超额收益的来源"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="perspectiveRightIn3"
          outEffect="fadeOut"
        />
      </div>
      <div class="roudCircle">
        <img
          src="../../assets/img/zhongxin/page21_circleBg.png"
          width="100%"
          height="100%"
        />
      </div>
      <img
        class="arrow1"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <img
        class="arrow2"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <img
        class="arrow3"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <div class="line line1"></div>
      <div class="line line2"></div>
      <div class="textBlock textBlock1">
        <div class="inner">
          核心策略一：大类资产配置 <br />
          · 战略配置<br />
          · 战术配置
        </div>
      </div>
      <div class="textBlock textBlock2">
        <div class="inner">
          核心策略二：基金精选 <br />
          · 基金公司维度<br />
          · 基金产品维度<br />
          · 基金经理维度<br />
          · 人工智能赋能
        </div>
      </div>
    </div>
    <!-- 第22页 -->
    <div key="22" class="card card22" v-if="currentPage == 22">
       <div class="audio_box"> 
          <audio ref="audio14" src="../../../public/audio/zhongxin/14.mp3"></audio>
      </div>
      <div class="leftCircle">
        <div class="innerCircle">核心库</div>
        <img
          src="../../assets/img/zhongxin/circleArrow_01.png"
          class="arrow arrow1"
        />
        <img
          src="../../assets/img/zhongxin/circleArrow_02.png"
          class="arrow arrow2"
        />
        <img
          src="../../assets/img/zhongxin/circleArrow_03.png"
          class="arrow arrow3"
        />
        <div class="circleTxt circleTxt1">筛选</div>
        <div class="circleTxt circleTxt2">调研</div>
        <div class="circleTxt circleTxt3">跟踪</div>
      </div>
      <div class="rightList">
        <div class="block block1">
          <div class="tittle">基金公司</div>
          <div class="content">
            <p>
              <anime-txt
                ref="block1P1"
                id="block1P1"
                class="anime_txt"
                txt="定量：历史（负面清单）+规模+业绩+产品评级"
                duration="1000"
                easing="easeOutExpo"
                staggerSpeed="fast"
                inEffect="typeIn"
                outEffect="fadeOut"
              />
            </p>
            <p>
              <anime-txt
                ref="block1P2"
                id="block1P2"
                class="anime_txt"
                txt="定性：股权清晰+团队稳定+投决机制+产品体系"
                duration="1000"
                easing="easeOutExpo"
                staggerSpeed="fast"
                inEffect="typeIn"
                outEffect="fadeOut"
              />
            </p>
          </div>
        </div>
        <div class="block block2">
          <div class="tittle">基金经理</div>
          <div class="content">
            <p>
              <anime-txt
                ref="block2P1"
                id="block2P1"
                class="anime_txt"
                txt="对象：围绕投资经理进行调研和分析"
                duration="1000"
                easing="easeOutExpo"
                staggerSpeed="fast"
                inEffect="typeIn"
                outEffect="fadeOut"
              />
            </p>
            <p>
              <anime-txt
                ref="block2P2"
                id="block2P2"
                class="anime_txt"
                txt="措施：相关基金产品的历史研究均要综合考虑"
                duration="1000"
                easing="easeOutExpo"
                staggerSpeed="fast"
                inEffect="typeIn"
                outEffect="fadeOut"
              />
            </p>
          </div>
        </div>
        <div class="block block3">
          <div class="tittle">基金产品</div>
          <div class="content">
            <p>
              <anime-txt
                ref="block3P1"
                id="block3P1"
                class="anime_txt"
                txt="定量：分类别内部评级+持仓分析+收益率归因"
                duration="1000"
                easing="easeOutExpo"
                staggerSpeed="fast"
                inEffect="typeIn"
                outEffect="fadeOut"
              />
            </p>
            <p>
              <anime-txt
                ref="block3P2"
                id="block3P2"
                class="anime_txt"
                txt="定性：对基金经理调研逻辑、偏好、团队、产品"
                duration="1000"
                easing="easeOutExpo"
                staggerSpeed="fast"
                inEffect="typeIn"
                outEffect="fadeOut"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 第23页 -->
    <div key="23" class="cardSteps card card23" v-if="currentPage == 23">
       <div class="audio_box"> 
          <audio ref="audio15" src="../../../public/audio/zhongxin/15.mp3"></audio>
      </div>
      <div class="topLetter">
        <anime-txt
          ref="refAnimeTxt23"
          id="refAnimeTxt23"
          class="anime_txt"
          txt="FOF投资超额收益的来源"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="perspectiveRightIn3"
          outEffect="fadeOut"
        />
      </div>
      <div class="roudCircle">
        <img
          src="../../assets/img/zhongxin/page21_circleBg.png"
          width="100%"
          height="100%"
        />
      </div>
      <img
        class="arrow1"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <img
        class="arrow2"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <img
        class="arrow3"
        src="../../assets/img/zhongxin/page21_circle_03.png"
        width="203px"
        height="120"
      />
      <div class="line line1"></div>
      <div class="line line2"></div>
      <div class="line line3"></div>
      <div class="textBlock textBlock1">
        <div class="inner">
          核心策略一：大类资产配置 <br />
          · 战略配置<br />
          · 战术配置
        </div>
      </div>
      <div class="textBlock textBlock2 blockGray2">
        <div class="inner">
          核心策略二：基金精选 <br />
          · 基金公司维度<br />
          · 基金产品维度<br />
          · 基金经理维度<br />
          · 人工智能赋能
        </div>
      </div>
      <div class="textBlock textBlock3">
        <div class="inner">
          核心策略三：基金组合构建<br />
          · 基金组合构建<br />
          · 基金品种选择<br />
          · 组合动态管理<br />
        </div>
      </div>
    </div>
    <!-- 第24页 -->
    <div key="24" class="card card24" v-if="currentPage == 24">
       <div class="audio_box"> 
          <audio ref="audio16" src="../../../public/audio/zhongxin/16.mp3"></audio>
      </div>
      <div class="leftLetters">
        <anime-txt
          ref="refAnimeTxt24"
          id="refAnimeTxt24"
          class="anime_txt"
          txt="通过自上而下+中观轮动+产品优选的三层结构来进行FOF组合的构建，有效获取不同层次的阿尔法收益。"
          duration="100"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="rotateIn3"
          outEffect="fadeOut"
        />
      </div>
      <div class="pyramidWrap">
        <div class="pyramid pyramid1">
          <p class="row1">
            <b>资产配置：</b>
          </p>
          <p class="row2">经济周期 股债轮动</p>
        </div>
        <div class="pyramid pyramid2">
          <p class="row3"><b>风格轮动：</b> 主动VS被动，成长VS价值</p>
          <p class="row4 rowMt"><b>模板轮动：</b> 估值，盈利，动量</p>
        </div>
        <div class="pyramid pyramid3">
          <p class="row5"><b>基金优选：</b> 核心VS卫星，场内VS场外，评</p>
          <p class="row6 rowMt">
            <b>边际变化：</b> 流动性冲击VS政策冲击，风险事
          </p>
          <p class="row7 rowMt">
            <b>量化择时：</b> 趋势突破VS区间震荡，指数选择
          </p>
        </div>
      </div>
    </div>
    <!-- 第25页 -->
    <div key="25" class="card card25" v-if="currentPage == 25">
       <div class="audio_box"> 
          <audio ref="audio17" src="../../../public/audio/zhongxin/17.mp3"></audio>
      </div>
      <div class="circleWrap">
        <!-- 大气泡带文字 -->
        <div class="circle circle_purple" style="left: 172px; top: 229px">
          均衡全能型选股基金 <br />
          宽基指数增强基金
        </div>
        <div class="circle circle_blue" style="left: 0px; top: 207px">
          主题风格型 <br />
          选股基金
        </div>
        <div class="circle circle_red" style="left: 658px; top: 0px">
          主题风格型被 <br />
          动指数基金
        </div>
        <div class="circle circle_yellow" style="left: 783px; top: 500px">
          被动指数 <br />
          基金
        </div>
        <!-- 小气泡，没文字 -->
        <div
          class="circle circle_purpleS1"
          style="left: 979px; top: 329px"
        ></div>
        <div
          class="circle circle_purpleS2"
          style="left: 38px; top: 436px"
        ></div>
        <div class="circle circle_redS1" style="left: 166px; top: 693px"></div>
        <div class="circle circle_redS2" style="left: 785px; top: 689px"></div>
        <div
          class="circle circle_yellowS1"
          style="left: -49px; top: 225px"
        ></div>
        <div
          class="circle circle_yellowS2"
          style="left: 654px; top: 401px"
        ></div>
        <div
          class="circle circle_yellowS3"
          style="left: 633px; top: 164px"
        ></div>
      </div>
      <!-- 右侧表格 -->
      <div class="tableWrap">
        <table class="redTable" border="0" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th>阶段</th>
              <th>收益率<br />差异度</th>
              <th>收益秩<br />相关性</th>
              <th>结论</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>牛市后期</td>
              <td>向上</td>
              <td>向上</td>
              <td>超配主动基金</td>
            </tr>
            <tr>
              <td>熊市后期</td>
              <td>向上</td>
              <td>向下</td>
              <td>超配主动基金</td>
            </tr>
            <tr>
              <td>牛市前期</td>
              <td>向下</td>
              <td>向上</td>
              <td>超配主动基金</td>
            </tr>
            <tr>
              <td>熊市前期</td>
              <td>向下</td>
              <td>向下</td>
              <td>超配主动基金</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Decimal from "decimal";
import animeTxt from "@/components/animeTxt/index.vue";
import { sleep } from "@/utils/common";
export default {
  name: "tree",
  props: ["cardData"],
  data: function () {
    return {
      currentPage: 19,
      bardata: [],
      barChartOption: {
        grid: {
          left: "95px",
          right: "0",
          top: "0",
          bottom: "0",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            position: "bottom",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              textStyle: {
                color: function (value) {
                  if (value > 0) {
                    return "#E54444";
                  } else if (value < 0) {
                    return "#00B466";
                  } else {
                    return "#999";
                  }
                },
                fontSize: 18,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: [],
            inverse: true,
            axisLabel: {
              textStyle: {
                margin: 20,
                fontSize: 21,
                color: "#333333",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: [],
            barWidth: 24,
            label: {
              show: false,
            },
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(0,0,0,.1)",
              borderRadius: 20,
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data >= 0) {
                    return {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 1,
                      y2: 0,
                      colorStops: [
                        { offset: 0, color: "rgba(255, 175, 175, 1)" },
                        { offset: 1, color: "rgba(255, 119, 119, 1)" },
                      ],
                    };
                  } else {
                    return {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 1,
                      y2: 0,
                      colorStops: [
                        { offset: 0, color: "rgba(146, 208, 80, 1)" },
                        { offset: 1, color: "rgba(218, 255, 179, 1)" },
                      ],
                    };
                  }
                },
                borderRadius: function (params) {
                  if (params.data >= 0) {
                    return [0, 20, 20, 0];
                  } else {
                    return [0, 0, 20, 20];
                  }
                },
              },
            },
          },
        ],
      },
    };
  },
  components: {
    animeTxt,
  },
  mounted() {},
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      await this.page19Fn();
      await sleep(1000);
      this.currentPage = 20;
      await sleep(100);
      await this.page20Fn();
      await sleep(1000);
      this.currentPage = 21;
      await sleep(100);
      await this.page21Fn();
      await sleep(1000);
      this.currentPage = 22;
      await sleep(100);
      await this.page22Fn();
      await sleep(1000);
      this.currentPage = 23;
      await sleep(100);
      await this.page23Fn();
      await sleep(1000);
      this.currentPage = 24;
      await sleep(100);
      await this.page24Fn();
      await sleep(1000);
      this.currentPage = 25;
      await sleep(100);
      await this.page25Fn();
      await sleep(1000);
      this.$emit('playEnd')
    },
    // 第19页 第一步
    async page19Fn() {
      console.log("page19Fn...");
      this.$refs.audio11.play()
      this.$refs.refAnimeTxt19.playIn();
      await sleep(2000);
      // 背景转一圈
      $(".roudCircle").css({ opacity: 1 });
      $(".roudCircle").addClass("circle ");
      await sleep(2000);
      //箭头1展示
      $(".arrow1").css({ opacity: 1 });
      $(".arrow1").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //内容1展示
      $(".textBlock1").css({ opacity: 1 });
      $(".textBlock1").addClass("shadowSlideDown");
      await sleep(1000);
      //虚线1展示
      $(".line1").css({ opacity: 1 });
      $(".line1").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      //箭头2展示
      $(".arrow2").css({ opacity: 1 });
      $(".arrow2").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //箭头3展示
      $(".arrow3").css({ opacity: 1 });
      $(".arrow3").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //出场
      $(".card19").addClass("animate__animated animate__flipOutX ");
    },
    // 龙虎榜-营业部净买入个股
    loadChart(idx) {
      // 基于准备好的dom，初始化this.$echarts实例
      const className = "chart" + idx;
      var myChart = this.$echarts.init(document.getElementById(className));
      // 指定图表的配置项和数据
      console.log(this.cardData);
      var option = this.refreshOption(idx);
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    refreshOption(idx) {
      var option = _.cloneDeep(this.barChartOption);
      const tempData = this.cardData.chart_data_5[0].data;
      const label = "产品_" + idx;
      const value = "涨跌幅_" + idx;
      option.yAxis[0].data = tempData[label];
      option.series[0].data = tempData[value];
      //bardata show
      this.bardata.push(tempData[value]);
      this.showBarData(idx);
      const sizeObj = this.ComputeMax(tempData[value]);
      console.log(JSON.stringify(sizeObj));
      option.xAxis[0].max = sizeObj.max;
      option.xAxis[0].min = sizeObj.min;
      return option;
    },
    ComputeMax(dataArr) {
      console.log(JSON.stringify(dataArr));
      let max = 0;
      let min = 0;
      let len = dataArr.length;
      for (let i = 0; i < len; i++) {
        dataArr[i] = parseFloat(dataArr[i]);
        if (max < dataArr[i]) max = dataArr[i];
        if (min > dataArr[i]) min = dataArr[i];
      }
      const plusSize = 1.2;
      max = Math.ceil(new Decimal(max).mul(new Decimal(plusSize)));
      min = Math.floor(new Decimal(min).mul(new Decimal(plusSize)));
      return {
        max,
        min,
      };
    },
    showBarData(idx) {
      const childClassName = ".column" + idx + " li";
      let liIdx = 0;
      let timer;
      timer = setInterval(function () {
        if (liIdx >= $(childClassName).length) {
          clearInterval(timer);
          timer = null;
        } else {
          $(childClassName).eq(liIdx).css({ opacity: 1 });
          $(childClassName)
            .eq(liIdx)
            .addClass("animate__animated animate__bounceIn");
          liIdx++;
        }
      }, 100);
    },
    // 第20页 
    async page20Fn() {
      console.log("page20Fn...");
       this.$refs.audio12.play()
      //顶部标题
      this.$refs.refAnimeTxt20.playIn();
      await sleep(2000);
      // 第一块柱状图
      $(".block1").css({ opacity: 1 });
      $(".block1").addClass("animate__animated animate__fadeIn");
      $(".tittle1").addClass("animate__animated animate__bounceIn");
      this.loadChart(1);
      await sleep(4000);
      // 第二块柱状图
      $(".block2").css({ opacity: 1 });
      $(".block2").addClass("animate__animated animate__fadeIn");
      $(".tittle2").addClass("animate__animated animate__bounceIn");
      this.loadChart(2);
      await sleep(4000);
      // 第三块柱状图
      $(".block3").css({ opacity: 1 });
      $(".block3").addClass("animate__animated animate__fadeIn");
      $(".tittle3").addClass("animate__animated animate__bounceIn");
      this.loadChart(3);
      await sleep(4000);
      //黑条目显示
      $(".blackRow").css({ opacity: 1 });
      $(".blackRow").addClass("animate__animated animate__fadeIn");
      this.$refs.refAnimeTxt20b.playIn();
      await sleep(1500);
      //退场
      //黑条目消失
      this.$refs.refAnimeTxt20b.playOut();
      await sleep(100);
      $(".blackRow").removeClass("animate__animated animate__fadeIn");
      $(".blackRow").addClass("animate__animated animate__fadeOut");
      $(".blackRow").css({ opacity: 0 });
      await sleep(500);
      //整个退场
      $(".card20").addClass("animate__animated animate__bounceOut");
    },
    // 第21页 第二步
    async page21Fn() {
      console.log("page21Fn...");
       this.$refs.audio13.play()
      this.$refs.refAnimeTxt21.playIn();
      // await sleep(2000);
      // 背景转一圈
      $(".roudCircle").css({ opacity: 1 });
      $(".roudCircle").addClass("circle ");
      // await sleep(2000);
      //箭头1展示
      $(".arrow1").css({ opacity: 1 });
      $(".arrow1").addClass("animate__animated animate__fadeIn ");
      // await sleep(1000);
      //内容1展示
      $(".textBlock1").css({ opacity: 1 });
      $(".textBlock1").addClass("shadowSlideDown");
      // await sleep(1000);
      //虚线1展示
      $(".line1").css({ opacity: 1 });
      $(".line1").addClass("animate__animated animate__fadeIn");
      await sleep(2000);
      //箭头2展示
      $(".arrow2").css({ opacity: 1 });
      $(".arrow2").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //内容2展示
      $(".textBlock2").css({ opacity: 1 });
      $(".textBlock2").addClass("shadowSlideDown");
      await sleep(1000);
      //虚线2展示
      $(".line2").css({ opacity: 1 });
      $(".line2").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //箭头3展示
      $(".arrow3").css({ opacity: 1 });
      $(".arrow3").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //出场
      $(".card21").addClass("animate__animated animate__flipOutY ");
    },
    // 第22页
    async page22Fn() {
      console.log("page22Fn...");
       this.$refs.audio14.play()
      //左侧圆展示
      await this.leftCircleShow();
      await this.leftCircleRound();
       await sleep(500);
      //右侧文案
      this.rightListShow(1);
      await sleep(3200);
      this.rightListShow(2);
      await sleep(3200);
      this.rightListShow(3);
      await sleep(3200);
      //出场
      $(".card22").addClass("animate__animated animate__rotateOut ");
    },
    async leftCircleShow() {
      $(".innerCircle").css({ opacity: 1 });
      $(".innerCircle").addClass("animate__animated animate__rotateIn ");
      await sleep(500);
      $(".innerCircle").removeClass("animate__animated animate__fadeIn ");
      $(".innerCircle").addClass("animate__animated animate__bounceIn ");
      await sleep(500);
      $(".arrow1").css({ opacity: 1 });
      $(".arrow1").addClass("animate__animated animate__fadeIn ");
      await sleep(800);
      $(".circleTxt1").css({ opacity: 1 });
      $(".circleTxt1").addClass("animate__animated animate__fadeIn ");
      await sleep(800);
      $(".arrow2").css({ opacity: 1 });
      $(".arrow2").addClass("animate__animated animate__fadeIn ");
      await sleep(800);
      $(".circleTxt2").css({ opacity: 1 });
      $(".circleTxt2").addClass("animate__animated animate__fadeIn ");
      await sleep(800);
      $(".arrow3").css({ opacity: 1 });
      $(".arrow3").addClass("animate__animated animate__fadeIn ");
      await sleep(800);
      $(".circleTxt3").css({ opacity: 1 });
      $(".circleTxt3").addClass("animate__animated animate__fadeIn ");
      await sleep(800);
    },
    leftCircleRound() {
      $(".leftCircle").addClass("rotate");
      $(".circleTxt1").addClass("rotateReverse");
      $(".circleTxt2").addClass("rotateReverse");
      $(".circleTxt3").addClass("rotateReverse");
      $(".innerCircle").addClass("rotateReverse");
    },
    async rightListShow(idx) {
      $(".block" + idx).css({ opacity: 1 });
      $(".block" + idx).addClass("animate__animated animate__flipInX ");
      await sleep(800);
      $(".block" + idx + " .tittle").css({ opacity: 1 });
      $(".block" + idx + " .tittle").addClass(
        "animate__animated animate__bounceIn "
      );
      await sleep(800);
      this.$refs["block" + idx + "P1"].playIn();
      await sleep(800);
      this.$refs["block" + idx + "P2"].playIn();
      await sleep(800);
    },
    // 第23页 第三步
    async page23Fn() {
      console.log("page23Fn...");
       this.$refs.audio15.play()
      this.$refs.refAnimeTxt23.playIn();
      // await sleep(2000);
      // 背景转一圈
      $(".roudCircle").css({ opacity: 1 });
      $(".roudCircle").addClass("circle ");
      // await sleep(2000);
      //箭头1展示
      $(".arrow1").css({ opacity: 1 });
      $(".arrow1").addClass("animate__animated animate__fadeIn ");
      // await sleep(1000);
      //内容1展示
      $(".textBlock1").css({ opacity: 1 });
      $(".textBlock1").addClass("shadowSlideDown");
      // await sleep(1000);
      //虚线1展示
      $(".line1").css({ opacity: 1 });
      $(".line1").addClass("animate__animated animate__fadeIn");
      // await sleep(1000);
      //箭头2展示
      $(".arrow2").css({ opacity: 1 });
      $(".arrow2").addClass("animate__animated animate__fadeIn ");
      // await sleep(1000);
      //内容2展示
      $(".textBlock2").css({ opacity: 1 });
      $(".textBlock2").addClass("shadowSlideDown");
      // await sleep(1000);
      //虚线2展示
      $(".line2").css({ opacity: 1 });
      $(".line2").addClass("animate__animated animate__fadeIn ");
      await sleep(2000);
      //箭头3展示
      $(".arrow3").css({ opacity: 1 });
      $(".arrow3").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //内容3展示
      $(".textBlock3").css({ opacity: 1 });
      $(".textBlock3").addClass("shadowSlideDown");
      await sleep(1000);
      //虚线3展示
      $(".line3").css({ opacity: 1 });
      $(".line3").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
      //出场
      $(".card23").addClass("animate__animated animate__rotateOut ");
    },
    // 第24页
    async page24Fn() {
      console.log("page24Fn...");
       this.$refs.audio16.play()
      this.$refs.refAnimeTxt24.playIn();
      await sleep(3000);
      //金字塔由低到高
      //第一层
      $(".pyramid3").css({ opacity: 1 });
      $(".pyramid3").addClass("animate__animated animate__fadeInUp ");
      await sleep(500);
      $(".row5").css({ opacity: 1 });
      $(".row5").addClass("animate__animated animate__fadeInUp ");
      await sleep(500);
      $(".row6").css({ opacity: 1 });
      $(".row6").addClass("animate__animated animate__fadeInUp ");
      await sleep(500);
      $(".row7").css({ opacity: 1 });
      $(".row7").addClass("animate__animated animate__fadeInUp ");
      await sleep(1500);
      //第二层
      $(".pyramid2").css({ opacity: 1 });
      $(".pyramid2").addClass("animate__animated animate__fadeInUp ");
      await sleep(500);
      $(".row3").css({ opacity: 1 });
      $(".row3").addClass("animate__animated animate__fadeInUp ");
      await sleep(500);
      $(".row4").css({ opacity: 1 });
      $(".row4").addClass("animate__animated animate__fadeInUp ");
      await sleep(1000);
      //第三层
      $(".pyramid1").css({ opacity: 1 });
      $(".pyramid1").addClass("animate__animated animate__fadeInUp ");
      await sleep(500);
      $(".row1").css({ opacity: 1 });
      $(".row1").addClass("animate__animated animate__fadeInUp ");
      await sleep(500);
      $(".row2").css({ opacity: 1 });
      $(".row2").addClass("animate__animated animate__fadeInUp ");
      await sleep(1000);
      //出场
      $(".card24").addClass("animate__animated animate__fadeOutUp ");
    },
    // 第25页
    async page25Fn() {
      console.log("page25Fn...");
       this.$refs.audio17.play()
      await this.circleShow();
      await sleep(2000);
      $(".circleWrap").addClass("slideLeft");
      await sleep(2000);
      await this.circleFloat();
      $(".tableWrap").css({ opacity: 1 });
      $(".tableWrap").addClass("shadowSlideDown tableSlideDown");
      await sleep(5000);
      //出场
      $(".card25").addClass("animate__animated animate__bounceOut ");
    },
    // 圈圈显示
    async circleShow() {
      const circles = $(".circle");
      const len = circles.length;
      let tempArr = [];
      for (let i = 0; i < 11; i++) {
        let tempObj = this.roundomIdx(tempArr, len);
        // console.log("tempObj:" + JSON.stringify(tempObj));
        tempArr = tempObj.arr;
        let idx = tempObj.idx;
        // console.log("out idx:" + idx);
        // console.log(idx);
        $(".circle").eq(idx).css("opacity", 1);
        $(".circle").eq(idx).addClass("animate__animated animate__bounceIn ");
        await sleep(100);
      }
    },
    async circleFloat() {
      const circles = $(".circle");
      const len = circles.length;
      let tempArr = [];
      for (let i = 0; i < 11; i++) {
        let tempObj = this.roundomIdx(tempArr, len);
        tempArr = tempObj.arr;
        let idx = tempObj.idx;
        if (idx > 3) {
          let className = "float" + ((idx % 4) + 1);
          $(".circle").eq(idx).addClass(className);
          await sleep(150);
        }
      }
    },
    //随机抽取其中一个cirle的索引
    roundomIdx(arr, len) {
      let idx = Math.floor(Math.random() * len);
      let result;
      // console.log("inner idx" + idx);
      let boolean = arr.filter((value) => value == idx).length > 0;
      // console.log("boolean:" + boolean);
      if (boolean) {
        return this.roundomIdx(arr, len);
      } else {
        arr.push(idx);
        result = {
          idx,
          arr,
        };
      }
      // console.log("roundomIdx:" + JSON.stringify(result));
      return result;
    },
  },
};
</script>
<style scoped lang="less">
// 通用
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.audio_box{
  height: 0;
  width:0;
  line-height: 0;
}
.card {
  background: transparent;
  color: #000;
}
.topLetter {
  text-align: center;
  padding-top: 155px;
  color: #333333;
  .anime_txt {
    font-size: 60px;
  }
}
.shadowSlideDown::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  animation: shadowSlideDownAnimate 2s;
}
.tableSlideDown::after {
  animation-duration: 4s;
}
@keyframes shadowSlideDownAnimate {
  0% {
    opacity: 1;
    height: 100%;
  }
  100% {
    height: 0;
    opacity: 1;
  }
}
// 圆圈步骤
.cardSteps {
  .roudCircle {
    width: 600px;
    height: 600px;
    position: absolute;
    left: 658px;
    top: 269px;
    opacity: 0;
  }
  .circle {
    animation: circleAnimate 2s;
  }
  @keyframes circleAnimate {
    0% {
      transform: rotate(-90deg);
    }
    75% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  .arrow1 {
    position: absolute;
    left: 782px;
    top: 313px;
    opacity: 0;
  }

  .arrow2 {
    position: absolute;
    left: 1069px;
    top: 482px;
    opacity: 0;
    transform: rotate(107deg);
  }

  .arrow3 {
    position: absolute;
    left: 844px;
    top: 727px;
    opacity: 0;
    transform: rotate(212deg);
  }
  .textBlock {
    display: flex;
    align-items: center;
    font-size: 33px;
    columns: #000;
    line-height: 1.5em;
    position: absolute;
    opacity: 0;
    .inner {
      padding-left: 20px;
    }
  }
  .textBlock1 {
    width: 436px;
    height: 260px;
    left: 195px;
    top: 311px;
    background: url(../../assets/img/zhongxin/rectangle1.png);
    background-size: 100% 100%;
  }
  .blockActive1 {
    background: url(../../assets/img/zhongxin/rectangle1a.png);
  }
  .textBlock2 {
    width: 436px;
    height: 380px;
    left: 1263px;
    top: 251px;
    background: url(../../assets/img/zhongxin/rectangle2a.png);
    background-size: 100% 100%;
  }
  .blockGray2 {
    background: url(../../assets/img/zhongxin/rectangle2.png);
  }
  .textBlock3 {
    width: 436px;
    height: 300px;
    left: 1210px;
    top: 680px;
    background: url(../../assets/img/zhongxin/rectangle3a.png);
    background-size: 100% 100%;
  }
  .line {
    width: 120px;
    border-bottom: dashed 5px #ee4560;
    position: absolute;
    opacity: 0;
  }
  .line1 {
    left: 660px;
    top: 420px;
    transform: rotate(25deg);
  }
  .line2 {
    left: 1126px;
    top: 385px;
    transform: rotate(-25deg);
  }
  .line3 {
    left: 1069px;
    top: 800px;
    transform: rotate(25deg);
  }
}
// 第20页
.barChartWrap {
  display: flex;
  width: 1664px;
  margin: 0 auto;
  justify-content: space-between;
  .block {
    width: 540px;
    height: 730px;
    padding: 20px 30px;
    border-radius: 20px;
    opacity: 0;
    .chart {
      width: 80%;
      height: 100%;
    }
  }
  .block:nth-child(1) {
    background: #fff6f6;
  }
  .block:nth-child(2) {
    background: #e5f4ff;
    .flexRow {
      border-color: #9fd6ff;
    }
  }
  .block:nth-child(3) {
    background: #f4effa;
    .flexRow {
      border-color: rgba(98, 54, 255, 0.29);
    }
  }
  .tittle {
    color: #333333;
    font-size: 32px;
    text-align: center;
  }
  .flexRow {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 20px;
    margin-top: 15px;
    height: 590px;
    border: dashed 2px #f2a6a6;
    .column {
      li {
        opacity: 0;
        line-height: 45.5px;
        list-style: none;
        color: #6d7278;
        font-size: 22px;
      }
    }
  }
}
.blackRow {
  opacity: 0;
  background: #011a2e;
  position: absolute;
  width: 100%;
  height: 380px;
  left: 0;
  top: 50%;
  margin-top: -190px;
  color: #fff;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .anime_txt {
    font-size: 100px;
  }
}
.card22 {
  .leftCircle {
    left: 228px;
    top: 374px;
    width: 440px;
    height: 440px;
    position: absolute;
    .innerCircle {
      opacity: 0;
      width: 240px;
      height: 240px;
      border-radius: 100%;
      background: #ffeeee;
      border: solid 30px #ffd6d6;
      color: rgba(0, 0, 0, 0.85);
      font-size: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 109px;
      top: 100px;
    }
    .arrow {
      opacity: 0;
    }
    .arrow1 {
      position: absolute;
      top: -3px;
      left: -3px;
    }
    .arrow2 {
      position: absolute;
      top: 3px;
      left: 317px;
    }
    .arrow3 {
      position: absolute;
      bottom: -11px;
      left: 58px;
    }
    .circleTxt {
      opacity: 0;
      position: absolute;
      width: 165px;
      height: 165px;
      border: dashed 2px #ffbdbd;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
    }
    .circleTxt1 {
      left: 147px;
      top: -95px;
    }
    .circleTxt2 {
      top: 205px;
      right: -95px;
    }
    .circleTxt3 {
      left: -81px;
      top: 205px;
    }
  }
  .rotate {
    animation: rotateAnimate 50s linear infinite;
  }
  @keyframes rotateAnimate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  .rotateReverse {
    display: inline-block;
    animation: rotateReverseAnimate 50s linear infinite;
  }
  @keyframes rotateReverseAnimate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
  .rightList {
    position: absolute;
    left: 840px;
    top: 170px;
  }
  .block {
    opacity: 0;
    margin-bottom: 40px;
    background: #e4f3ff;
    border-radius: 16px;
    color: #6d7278;
    font-size: 36px;
    padding: 80px 57px 20px 33px;
    position: relative;
    .tittle {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../../assets/img/zhongxin/blueTitleBg.png) no-repeat;
      background-size: 100% 100%;
      color: #000;
      text-align: center;
      line-height: 67px;
      width: 223px;
      height: 67px;
    }
    .content {
      p:nth-child(2) {
        margin-top: 5px;
      }
    }
  }
  .block2 {
    background: #f2eeff;
    .tittle {
      background-image: url(../../assets/img/zhongxin/purpleTitleBg.png);
    }
  }
  .block3 {
    background: #ddfff7;
    margin-bottom: 0;
    .tittle {
      background-image: url(../../assets/img/zhongxin/greenTitleBg.png);
    }
  }
}
.card24 {
  .leftLetters {
    position: absolute;
    width: 546px;
    height: 150px;
    left: 160px;
    top: 465px;
    .anime_txt {
      font-size: 32px;
    }
  }
  .pyramidWrap {
    position: absolute;
    width: 962px;
    left: 705px;
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .pyramid {
      opacity: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 34px;
      p {
        opacity: 0;
      }
    }
    .anime_txt {
      font-size: 34px;
    }
  }
  .pyramid1 {
    width: 630px;
    height: 262px;
    padding: 30px 60px 0 0;
    align-items: center;
    background: url(../../assets/img/zhongxin/pyramid1.png) no-repeat;
    background-size: 100% 100%;
  }
  .pyramid2 {
    width: 792px;
    height: 262px;
    padding: 30px 60px 0 80px;
    background: url(../../assets/img/zhongxin/pyramid2.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 10px;
  }
  .pyramid3 {
    width: 962px;
    height: 262px;
    padding: 30px 60px 0 100px;
    margin-top: 10px;
    background: url(../../assets/img/zhongxin/pyramid3.png) no-repeat;
    background-size: 100% 100%;
  }
  .rowMt {
    margin-top: 10px;
  }
}
.card25 {
  .circleWrap {
    width: 1164px;
    height: 850px;
    position: absolute;
    left: 393px;
    top: 109px;
    .circle {
      position: absolute;
      background: url(../../assets/img/zhongxin/circle_purple.png) no-repeat;
      background-size: 100% 100%;
      width: 338px;
      height: 338px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      text-align: center;
      opacity: 0;
    }
    // 紫色
    .circle_purple {
      width: 617px;
      height: 617px;
      font-size: 38px;
    }
    // 蓝色
    .circle_blue {
      background-image: url(../../assets/img/zhongxin/circle_blue.png);
    }
    //红色
    .circle_red {
      background-image: url(../../assets/img/zhongxin/circle_red.png);
    }
    //黄色
    .circle_yellow {
      background-image: url(../../assets/img/zhongxin/circle_yellow.png);
      font-size: 32px;
    }
    //小号
    .circle_purpleS1 {
      background-image: url(../../assets/img/zhongxin/circle_purpleS1.png);
      width: 123px;
      height: 123px;
    }
    .circle_purpleS2 {
      background-image: url(../../assets/img/zhongxin/circle_purpleS2.png);
      width: 70px;
      height: 70px;
    }
    .circle_redS1 {
      background-image: url(../../assets/img/zhongxin/circle_redS1.png);
      width: 105px;
      height: 105px;
    }
    .circle_redS2 {
      background-image: url(../../assets/img/zhongxin/circle_redS2.png);
      width: 90px;
      height: 90px;
    }
    .circle_yellowS1 {
      background-image: url(../../assets/img/zhongxin/circle_yellowS1.png);
      width: 151px;
      height: 151px;
    }
    .circle_yellowS2 {
      background-image: url(../../assets/img/zhongxin/circle_yellowS2.png);
      width: 100px;
      height: 100px;
    }
    .circle_yellowS3 {
      background-image: url(../../assets/img/zhongxin/circle_yellowS3.png);
      width: 50px;
      height: 50px;
    }
  }
  .slideLeft {
    animation: slideLeftAnimate 2s;
    animation-fill-mode: forwards;
  }
  @keyframes slideLeftAnimate {
    0% {
    }
    100% {
      transform: translate(-498px, 0px) scale(0.5);
    }
  }

  .float1 {
    animation: float1Animate 15s infinite;
  }
  @keyframes float1Animate {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(-30%, -40%);
    }
    50% {
      transform: translate(25%, -25%);
    }
    75% {
      transform: translate(-25%, 35%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .float2 {
    animation: float2Animate 15s infinite;
  }
  @keyframes float2Animate {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(35%, -25%);
    }
    50% {
      transform: translate(-30%, -30%);
    }
    75% {
      transform: translate(20%, 30%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .float3 {
    animation: float3Animate 15s infinite;
  }
  @keyframes float3Animate {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(-25%, 25%);
    }
    50% {
      transform: translate(25%, -20%);
    }
    75% {
      transform: translate(25%, -35%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .float4 {
    animation: float4Animate 15s infinite;
  }
  @keyframes float4Animate {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(25%, 20%);
    }
    50% {
      transform: translate(-25%, 20%);
    }
    75% {
      transform: translate(35%, -30%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .tableWrap {
    border: solid 3px #c3161c;
    width: 748px;
    height: 516px;
    position: absolute;
    left: 997px;
    top: 277px;
    opacity: 0;
  }
  .redTable {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    text-align: center;
    td,
    th {
      border: none;
      font-weight: normal;
      line-height: 1.2em;
      padding: 15px;
    }
    thead {
      background: #d03a26 url(../../assets/img/zhongxin/tableHeadBg.png)
        no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 32px;
      vertical-align: middle;
    }
    tbody {
      font-size: 28px;
      color: #333333;
      tr:nth-child(2n + 1) {
        background: rgba(216, 216, 216, 0.26);
      }
      tr:nth-child(2n) {
        background: #fff;
      }
    }
  }
}
</style>

